import deepmerge from "deepmerge";
import { chat } from '../'
import { createModalRoot } from './modal'

export function initializeChatWidget(iframe, botConfig, callback) {
  const theme = botConfig.theme
  iframe.style.zIndex = theme.widget.dimension.zIndex;
  iframe.style.width = theme.widget.dimension.maxWidth;
  iframe.style.height = theme.widget.dimension.minHeight;
  iframe.style.borderRadius = `${theme.widget.header.borderTopLeft ?? '0px'} ${theme.widget.header.borderTopRight} 0px 0px` 

  iframe.allow = "microphone";
  const classesToAdd = [
    'solo-autonom8-chat-widget-frame',
    'a8_animated',
    'a8_hidden',
  ];
  iframe.classList.add(...classesToAdd);
  document.body.appendChild(iframe);
  createWidgetIcon(theme)
  createModalRoot()
  callback()
}

function createWidgetIcon(theme) {
  const widget = document.createElement("div");
  widget.id = "solo-widget-icon-wrapper"
  widget.style.cssText = `right:${theme.widget.dimension.right};z-index:${theme.widget.dimension.zIndex};bottom:${theme.widget.dimension.bottom};position:fixed;`;

  const widgetIcon = document.createElement("div");
  widgetIcon.id = "solo-widget-icon"

  if (theme.widgetIcon.icon) {
    const widgetImg = new Image();
    widgetImg.onload = function () {
        const popupStr = document.createElement("div");
        popupStr.classList.add(...["talk-bubble", "tri-right", "round", "right-in"]);
        popupStr.onclick = chat.openChat;

        const talkStr = document.createElement("div");
        talkStr.classList.add(["talktext"]);

        const notificationText = document.createElement("p");
        notificationText.classList.add(["bubble-message"]);

        talkStr.appendChild(notificationText);

        popupStr.appendChild(talkStr);

        widget.appendChild(popupStr);
        loadChatWelcomeMessage(theme, notificationText);
    };
    widgetImg.src = theme.widgetIcon.icon;
    widgetImg.style.height = theme.widgetIcon.size + "px";
    widgetImg.style.width = "auto";
    widgetIcon.appendChild(widgetImg);
  } else {
    autonom8Widget.style.backgroundColor =
      theme.widget.dimension.primaryColor.hex;
    widgetIcon.innerHTML = chatIcon;
  }

  removeChatBubbleTimer(theme);

  widget.appendChild(widgetIcon);
  widgetIcon.onclick = chat.openChat
  document.body.appendChild(widget);
}

function removeChatBubbleTimer(theme) {
  const { calloutMessage } = theme.widget;
  let timeoutValue = 5000;
  if (calloutMessage && calloutMessage.timeoutValue) {
    timeoutValue = calloutMessage.timeoutValue * 1000;
  }
  setTimeout(() => {
    closeNotification();
  }, timeoutValue)
}

function loadChatWelcomeMessage(theme, notificationText) {
  const { calloutMessage } = theme.widget;
  let welcomeMessage = "Hello! How may I assist you?";
  if (calloutMessage && calloutMessage.text) {
    welcomeMessage = calloutMessage.text;
  }
  if (notificationText.childNodes[0]) {
    notificationText.childNodes[0].innerHTML = welcomeMessage;
  } else {
    const text = document.createElement("div");
    text.innerHTML = welcomeMessage;
    notificationText.appendChild(text);
  }
}

// merging an array of objects based on `name` field
export function combineMerge(target, source) {
  if (target.length && source.length) {
    const result = []
    const mergedFields = []
    for (let el of source) {
      for (let item of target) {
        if (el.name === item.name) {
          mergedFields.push(el.name)
          result.push({ ...item, ...el })
        }
      }
    }
    return [...result, ...target.filter(el => !mergedFields.includes(el.name))];
  } else {
    return target.length ? target : source;
  }
}

export async function mergeTheme(botConfig, theme) {
  const defaultTheme = JSON.parse(atob(botConfig.themeV2))
  if(theme?.payload) {
    return deepmerge(defaultTheme, theme.payload, { arrayMerge: combineMerge })
  } else if(theme?.link) {
    try {
      const res = await fetch(theme.link)
      const data = await res.json()
      return deepmerge(defaultTheme, data.theme, { arrayMerge: combineMerge })
    } catch(err) {
      console.log("error: failed to fetch theme via link:", err)
      return defaultTheme
    }
  }
  return defaultTheme
}


export function removeChatBubble() {
  const chatBubble = document.querySelector(".talk-bubble");
  if (chatBubble) {
    chatBubble.classList.add("close-notification")
  }
};

export const showUnreadMessage = (count) => {
  const badge = document.querySelector(".a8-badge");
  if(!badge) {
    const badge = document.createElement("div");
    badge.classList.add("a8-badge");
    badge.innerHTML = count;
    const widgetIcon = document.querySelector("#solo-widget-icon")
    if(widgetIcon) {
      widgetIcon.appendChild(badge)
    }
  } else {
    badge.innerHTML = count;
  }
  const bubbleMessage = document.querySelector(".bubble-message");
    if (bubbleMessage) {
     bubbleMessage.innerHTML = `You have ${count} new message${count > 1 ? 's' : ''}`;
  }
  const chatBubble = document.querySelector(".talk-bubble");
  if (chatBubble) {
    chatBubble.classList.remove("close-notification")
  }
};

export function openNotification() {
  const notification = document.querySelector('.talk-bubble');
  if (notification) {
    notification.classList.remove("close-notification");
  }
}

export function closeNotification() {
  const notification = document.querySelector('.talk-bubble');
  if (notification) {
    notification.classList.add("close-notification");
  }
}

export function toggleMaximize(theme, callback) {
  const iframe = document.querySelector(".solo-autonom8-chat-widget-frame");
  if(!iframe) {
    return
  }
  if (iframe.dataset.isMaximized !== "true") {
    iframe.dataset.isMaximized = "true"
    iframe.style.height = "95%";
    callback("onMaximize")
  } else {
    iframe.dataset.isMaximized = "false"
    iframe.style.height = theme.widget.dimension.minHeight;
    iframe.style.width = theme.widget.dimension.maxWidth;
    callback("onMinimize")
  }
}
