import deepmerge from 'deepmerge';
import { combineMerge, mergeTheme } from './'

export async function getClientId(baseURL, apiKey, userMetaInfo) {
  const url = `${baseURL}/a8solo/v1/newClient`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': apiKey
    },
    body: JSON.stringify(userMetaInfo),
  };
  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      throw new Error(`failed to get client id: received status code: ${res.status}`)
    }
    const data = await res.json()
    return data
  } catch (err) {
    throw new Error("failed to get client id")
  }
}

export async function getBotConfig(baseURL, appKey, config, clientId) {
  const { orgId, botHandle } = config
  const url = `${baseURL}/client/v1/configuration`
  const options = {
    headers: {
      'X-App-Key': appKey,
      'X-Bot-Handle': botHandle,
      'X-Org-Id': orgId,
      'X-Client-Id': clientId,
    },
  };
  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      throw new Error(`failed to get bot configuration: received status code: ${res.status}`)
    }
    const data = await res.json()
    if (config.aiAgentId) {
      const genaiTheme = await getGenAITheme(config.aiAgentId)
      const themePayload = genaiThemePayload(genaiTheme)
      config.theme = deepmerge(themePayload, config.theme || {}, { arrayMerge: combineMerge })
    }
    const theme = await mergeTheme(data.configuration, config.theme)
    data.configuration.theme = theme
    return data.configuration
  } catch (err) {
    throw new Error("error: failed to get bot configuration")
  }
}

export async function getGenAITheme(botID) {
  const url = `${window.GENAI_URL}/api/v1/bot/bots/${botID}/theme`
  try {
    const res = await fetch(url)
    if (!res.ok) {
      throw new Error(`genai theme: received status code: ${res.status}`)
    }
    const { theme } = await res.json()
    return theme
  } catch (err) {
    throw new Error("error: failed to get genai theme")
  }
}

function genaiThemePayload(theme) {
  return {
    payload: {
      widgetIcon: {
        icon: theme.icon,
      },
      widget: {
        dimension: {
          botName: theme.name,
        },
        header: {
          title: {
            text: theme.header.title,
          },
          subTitle: {
            text: theme.header.subTitle,
          }
        },
        messages: {
          globalSettings: {
            user: {
              backgroundColor: theme.primaryColor
            },
            agent: {
              backgroundColor: theme.secondaryColor
            }
          }
        }
      }
    }
  }
}
